import { render, staticRenderFns } from "./CYO.vue?vue&type=template&id=dc408f1c&scoped=true"
import script from "./CYO.vue?vue&type=script&lang=js"
export * from "./CYO.vue?vue&type=script&lang=js"
import style0 from "../../assets/css/components/tarjeta.css?vue&type=style&index=0&id=dc408f1c&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc408f1c",
  null
  
)

export default component.exports