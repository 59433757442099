<template>
  <transition name="fade-fast">
    <div v-if="mostrar && reemplazo" class="app-popup-container">
      <div class="app-popup-mask" @click="cerrar()"></div>
      <div class="reemplazo">
        <img
          class="reemplazo-cerrar"
          src="../assets/img/icons/close_black.png"
          @click="cerrar()"
        />
        <div class="reemplazo-container">
          <img v-if="reemplazo.reemplazado.imagen" :src="$localurl + '/' + reemplazo.reemplazado.imagen" />
          <img
            v-else
            src="../assets/img/icons/reemplazo.png"
          />
          <label>{{reemplazo.reemplazado.nombre}}</label>
          <p>Te asignó como responsable de su cuenta desde el día <span>{{getFechaInicio()}}</span> hasta <span>{{getFechaFin()}}</span></p>
        </div>
        <div class="reemplazo-info-container">
          <img src="../assets/img/info.png">
          <p>Al quedar como responsable de una cuenta, estarás a cargo de</p>
          <div class="reemplazo-infos">
            <div class="reemplazo-info">
              <img src="../assets/img/icons/check_box.png">
              <label>Verificar/Aprobar precios en C&O</label>
            </div>
            <div class="reemplazo-info">
              <img src="../assets/img/icons/check_box.png">
              <label>Verificar/Aprobar direcciones</label>
            </div>
            <div class="reemplazo-info">
              <img src="../assets/img/icons/check_box.png">
              <label>Verificar/Aprobar nuevos clientes</label>
            </div>
            <div class="reemplazo-info">
              <img src="../assets/img/icons/check_box.png">
              <label>Verificar/Aprobar stocks POG</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "reemplazopopup",
  components: { },
  props: {
    reemplazo: Object
  },
  data() {
    return {
      mostrar: true,
      meses: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
    };
  },
  created() {},
  mounted() {
    this.getReemplazo();
  },
  computed: {},
  methods: {
    cerrar() {
      this.mostrar = false;
    },
    getReemplazanteImagen(){
      const reemplazante = this.$store.getters.reemplazante;
      return reemplazante != null ? reemplazante.imagen : null;
    },
    getFechaInicio() {
      let mesNumero = this.reemplazo.inicio.split("-")[1] - 1;
      if (mesNumero == -1) {
        mesNumero = 11;
      }
      return this.reemplazo.inicio.split("-")[2] + " de " + this.meses[mesNumero];
    },
    getFechaFin() {
      let mesNumero = this.reemplazo.fin.split("-")[1] - 1;
      if (mesNumero == -1) {
        mesNumero = 11;
      }
      return this.reemplazo.fin.split("-")[2] + " de " + this.meses[mesNumero];
    }
  },
};
</script>

<style scoped src="../assets/css/components/reemplazopopup.css"></style>