<template>
  <div v-if="mostrar" class="tarjeta-parent">
    <div
      class="tarjeta tarjeta-body"
      style="background-color: #21a0d2"
      v-show="$usuario.rolPOG != null"
    >
      <div class="tarjeta-img">
        <img src="../../assets/img/tarjetas/POG.png" />
      </div>
      <div>
        <label class="tarjeta-titulo">{{ titulo }}</label>
        <label class="tarjeta-descripcion">{{ descripcion }}</label>
      </div>
      <img
        class="tarjeta-link"
        src="../../assets/img/botones/flecha.png"
        @click="ir()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "pog",
  components: {},
  props: {},
  data() {
    return {
      mostrar: false,
      configuracion: null,
      titulo: "",
      descripcion: "",
      dia: "",
      mes: "",
      meses: [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ],
    };
  },
  created() {},
  mounted() {
    this.$eventHub.$on("update", this.getPOG());
  },
  computed: {},
  methods: {
    ir() {
      let token = this.$cookies.get("token-landing");
      if (token == null) {
        token = localStorage.getItem("token-landing");
      }
      window.location.href = this.$pog_url + "/landing/" + token;
    },
    getPOG() {
      const that = this;
      this.$axios
        .get(this.$localurl + "/api/pog/configuracion")
        .then(function (response) {
          console.log(response);
          that.configuracion = response.data;
          let mesNumero = that.configuracion.fin.split("-")[1] - 1;
          if (mesNumero == -1) {
            mesNumero = 11;
          }
          that.mes = that.meses[mesNumero];
          let mesNumeroAnterior = that.configuracion.fin.split("-")[1] - 2;
          if (mesNumeroAnterior == -1) {
            mesNumeroAnterior = 11;
          } else if (mesNumeroAnterior == -2) {
            mesNumeroAnterior = 10;
          }
          let mesAnterior = that.meses[mesNumeroAnterior];
          let d = new Date();
          let inicio = new Date(that.configuracion.inicio + " 00:00:00");
          let fin = new Date(that.configuracion.fin + " 23:59:59");
          that.dia = fin.getDate();
          if (
            that.$usuario.rolPOG != null &&
            that.$usuario.rolPOG.detalle == "Distribuidor"
          ) {
            that.titulo =
              "Tenés tiempo hasta el " +
              that.dia +
              " de " +
              that.mes +
              " para cargar tu ventas mensuales de " +
              mesAnterior +
              " en POG";
            that.descripcion =
              "No podrás cargar tus ventas POG pasada la fecha límite";
          } else if (
            that.$usuario.rolPOG != null &&
            that.$usuario.rolPOG.detalle == "Ventas"
          ) {
            that.titulo =
              "Tenés tiempo hasta el " +
              that.dia +
              " de " +
              that.mes +
              " para revisar las ventas cargadas de " +
              mesAnterior +
              " en POG";
            that.descripcion =
              "No podrás revisar las cargas de tus clientes POG pasada la fecha límite";
          }
          if (
            d >= inicio &&
            d <= fin &&
            that.$usuario.rolPOG != null &&
            (that.$usuario.rolPOG.detalle == "Distribuidor" ||
              that.$usuario.rolPOG.detalle == "Ventas")
          ) {
            that.mostrar = true;
          } else {
            that.mostrar = false;
          }
        })
        .catch(function (response) {
          console.log(response);
        });
    },
  },
};
</script>

<style scoped src="../../assets/css/components/tarjeta.css"></style>