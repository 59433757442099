<template>
  <transition name="fade-fast">
    <div v-show="mostrar" class="app-popup-container">
      <div class="app-popup-mask" @click="cerrar()"></div>
      <div class="anuncio">
        <label class="anuncio-title">Crear nuevo anuncio</label>
        <img
          class="anuncio-cerrar"
          src="../assets/img/icons/close.png"
          @click="cerrar()"
        />
        <div class="anuncio-row2">
          <img v-if="$usuario.imagen == null" src="../assets/img/default.png" />
          <img
            v-if="$usuario.imagen != null"
            :src="$localurl + '/' + $usuario.imagen"
          />
          <Dropdown
            :options="roles"
            :placeholder="'Todos'"
            :paraArriba="false"
            :name="'detalle'"
            :conInput="true"
            ref="roles"
            v-on:updateOption="updateDropdownRoles"
          ></Dropdown>
        </div>
        <div class="anuncio-row">
          <label>Titulo del anuncio</label>
          <input
            type="text"
            placeholder="Escribe el título del anuncio"
            v-model="titulo"
            :class="{ 'error-input': errorTitulo }"
          />
          <div class="anuncio-colores">
            <div
              class="anuncio-color"
              style="backgroundcolor: #66c409"
              @click="color = '#66C409'"
            >
              <img
                v-show="color == '#66C409'"
                src="../assets/img/icons/check.png"
              />
            </div>
            <div
              class="anuncio-color"
              style="backgroundcolor: #373737"
              @click="color = '#373737'"
            >
              <img
                v-show="color == '#373737'"
                src="../assets/img/icons/check.png"
              />
            </div>
            <div
              class="anuncio-color"
              style="backgroundcolor: #21a0d2"
              @click="color = '#21A0D2'"
            >
              <img
                v-show="color == '#21A0D2'"
                src="../assets/img/icons/check.png"
              />
            </div>
            <div
              class="anuncio-color"
              style="backgroundcolor: #004a96"
              @click="color = '#004A96'"
            >
              <img
                v-show="color == '#004A96'"
                src="../assets/img/icons/check.png"
              />
            </div>
          </div>
        </div>
        <div class="anuncio-row">
          <label>Descripción</label>
          <textarea
            type="text"
            placeholder="Escribe una breve descripción"
            v-model="descripcion"
            :class="{ 'error-input': errorDescripcion }"
          ></textarea>
        </div>
        <div class="anuncio-row">
          <input
            class="anuncio-link"
            type="text"
            placeholder="Inserta Link de enlace"
            v-model="link"
            :class="{ 'error-input': errorLink }"
          />
        </div>
        <button class="anuncio-btn" @click="guardar()">Listo</button>
      </div>
    </div>
  </transition>
</template>

<script>
import Dropdown from "@/components/Dropdown.vue";

export default {
  name: "anunciopopup",
  components: { Dropdown },
  data() {
    return {
      idElegido: null,
      mostrar: false,
      roles: [
        {
          detalle: "Distribuidor - Dueño/Gerente/Director",
        },
        {
          detalle: "Distribuidor - Administrativo",
        },
        {
          detalle: "Distribuidor - Vendedor",
        },
        {
          detalle: "BASF",
        },
      ],
      rolElegido: null,
      titulo: "",
      color: "#66C409",
      descripcion: "",
      link: "",
      errorTitulo: false,
      errorDescripcion: false,
      errorLink: false,
    };
  },
  created() {},
  mounted() {
    this.$eventHub.$on("anuncio-abrir", () => (this.mostrar = true));
    this.$eventHub.$on("anuncio-editar", (anuncio) => this.editar(anuncio));
  },
  computed: {},
  methods: {
    cerrar() {
      this.mostrar = false;
      this.titulo = "";
      this.descripcion = "";
      this.link = "";
      this.errorTitulo = false;
      this.errorDescripcion = false;
      this.errorLink = false;
      this.idElegido = null;
      this.rolElegido = null;
      this.$refs.roles.selectedOption = null;
      this.$refs.roles.$refs.dropdownInput.value = "";
    },
    editar(anuncio) {
      this.titulo = anuncio.titulo;
      this.descripcion = anuncio.descripcion;
      this.link = anuncio.link;
      this.rolElegido = anuncio.rol;
      this.idElegido = anuncio.id;
      this.$refs.roles.selectedOption = this.roles.filter(
        (r) => r.detalle == anuncio.rol
      )[0];
      this.$refs.roles.$refs.dropdownInput.value = anuncio.rol;
      this.mostrar = true;
    },
    updateDropdownRoles(payload) {
      this.rolElegido = payload.detalle;
    },
    guardar() {
      let continuar = true;

      if (this.titulo == "") {
        this.errorTitulo = true;
        continuar = false;
      } else {
        this.errorTitulo = false;
      }

      if (this.descripcion == "") {
        this.errorDescripcion = true;
        continuar = false;
      } else {
        this.errorDescripcion = false;
      }

      if (this.link == "") {
        this.errorLink = true;
        continuar = false;
      } else {
        this.errorLink = false;
      }

      if (continuar) {
        const json = {
          id: this.idElegido,
          color: this.color,
          titulo: this.titulo,
          descripcion: this.descripcion,
          link: this.link,
          rol: this.rolElegido,
        };
        const that = this;
        this.$axios
          .put(this.$localurl + "/api/anuncio", json)
          .then(function () {
            that.$eventHub.$emit("anuncio-nuevo");
          })
          .catch(function (response) {
            console.log(response);
          });
        this.cerrar();
      }
    },
  },
};
</script>

<style scoped src="../assets/css/components/anunciopopup.css"></style>