<template>
  <transition name="fade-fast">
    <div v-show="mostrar" class="app-popup-container">
      <div class="app-popup-mask" @click="cerrar()"></div>
      <div class="herramienta">
        <label class="herramienta-title">Crear nueva herramienta</label>
        <img
          class="herramienta-cerrar"
          src="../assets/img/icons/close.png"
          @click="cerrar()"
        />
        <div
          class="herramienta-imagen"
          @click="cambiarImagen()"
          :class="{ 'error-input': errorImagen }"
        >
          <img src="../assets/img/icons/imagen.png" />
          <label v-show="imagen == null">Agregar imágen</label>
          <label v-show="imagen != null">{{ nombreImagen }}</label>
        </div>
        <input
          class="app-hide"
          type="file"
          ref="imagenUpload"
          @change="changeImagen()"
          accept="image/png, image/jpeg"
        />
        <div class="herramienta-row">
          <label>Nombra de herramienta</label>
          <input
            type="text"
            placeholder="Escribe el nombre de la herramienta"
            v-model="nombre"
            :class="{ 'error-input': errorNombre }"
          />
        </div>
        <div class="herramienta-row">
          <label>Hipervínculo</label>
          <input
            type="text"
            placeholder="Pega tu link aquí"
            v-model="link"
            :class="{ 'error-input': errorLink }"
          />
        </div>
        <button class="herramienta-btn" @click="guardar()">Listo</button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "herramienta",
  components: {},
  data() {
    return {
      idElegido: null,
      mostrar: false,
      nombreImagen: "",
      imagen: null,
      nombre: "",
      link: "",
      errorNombre: false,
      errorLink: false,
      errorImagen: false
    };
  },
  created() {},
  mounted() {
    this.$eventHub.$on("herramienta-abrir", () => (this.mostrar = true));
    this.$eventHub.$on("herramienta-editar", herramienta =>
      this.editar(herramienta)
    );
  },
  computed: {},
  methods: {
    cambiarImagen() {
      this.$refs.imagenUpload.click();
    },
    changeImagen() {
      const that = this;
      let reader = new FileReader();

      // Create a preview once image has loaded
      reader.onload = function() {
        that.reduceImageSize();
      };
      reader.readAsDataURL(this.$refs.imagenUpload.files[0]);
    },
    reduceImageSize() {
      // Read in file
      let file = this.$refs.imagenUpload.files[0];
      this.nombreImagen = file.name;
      const that = this;
      // Ensure it's an image
      if (file.type.match(/image.*/)) {
        console.log("An image has been loaded");

        // Load the image
        let reader = new FileReader();
        reader.onload = function(readerEvent) {
          let image = new Image();
          image.onload = function() {
            // Resize the image
            let canvas = document.createElement("canvas"),
              max_size = 200, // TODO : pull max size from a site config
              width = image.width,
              height = image.height;
            if (width > height) {
              if (width > max_size) {
                height *= max_size / width;
                width = max_size;
              }
            } else {
              if (height > max_size) {
                width *= max_size / height;
                height = max_size;
              }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext("2d").drawImage(image, 0, 0, width, height);
            let dataUrl = canvas.toDataURL("image/jpeg");
            that.imagen = dataUrl.replace(
              /^data:image\/(png|jpg|jpeg);base64,/,
              ""
            );
          };
          image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    cerrar() {
      this.mostrar = false;
      this.imagen = null;
      this.nombre = "";
      this.link = "";
      this.errorNombre = false;
      this.errorLink = false;
      this.idElegido = null;
    },
    editar(herramienta) {
      this.imagen = herramienta.imagen;
      this.nombreImagen = herramienta.imagenNombre;
      this.nombre = herramienta.nombre;
      this.link = herramienta.link;
      this.idElegido = herramienta.id;
      this.mostrar = true;
    },
    guardar() {
      let continuar = true;

      if (this.nombre == "") {
        this.errorNombre = true;
        continuar = false;
      } else {
        this.errorNombre = false;
      }

      if (this.link == "") {
        this.errorLink = true;
        continuar = false;
      } else {
        this.errorLink = false;
      }

      if (this.imagen == null) {
        this.errorImagen = true;
        continuar = false;
      } else {
        this.errorImagen = false;
      }

      if (continuar) {
        const json = {
          id: this.idElegido,
          imagen: this.imagen,
          imagenNombre: this.nombreImagen,
          nombre: this.nombre,
          link: this.link
        };
        this.$eventHub.$emit("herramienta-nueva", json);
        this.cerrar();
      }
    }
  }
};
</script>

<style>
@import "../assets/css/components/herramienta.css";
</style>
